import { ContentFilter } from "@dashboard-v3/api";
import { State } from "../types";
import produce from "immer";

const ruleTypeMap = new Map([
  ["COPY_EMAILS", "copyEmails"],
  ["MOVE_EMAILS", "moveEmails"],
  ["PROTECT_EMAILS", "protectEmails"],
  ["REPLACE_ATTACHMENTS", "replaceAttachments"],
  ["BLOCK_ATTACHMENTS", "blockAttachments"],
  ["DRAG_AND_DROP", "dragAndDrop"],
]);

const contentFilterMap = {
  BLOCK_ATTACHMENTS: "blockAttachments",
  REQUEST_APPROVAL: "requestApproval",
  SEND_DELAY: "sendDelay",
};

export function updateFilterAction(
  state: State,
  filter?: ContentFilter
): State {
  const ruleTypeWithContentFilter = contentFilterMap[state.type];

  if (ruleTypeWithContentFilter) {
    return produce(state, draft => {
      if (filter) {
        draft.rule[ruleTypeWithContentFilter].contentFilter = filter;
      } else {
        delete draft.rule[ruleTypeWithContentFilter].contentFilter;
      }
    });
  }

  const typeKey = ruleTypeMap.get(state.type);
  return produce(state, draft => {
    draft.rule[typeKey].contentToSave.filter = filter;
  });
}

export type UpdateFilterAction = {
  type: "updateFilterAction";
  payload: ContentFilter | null;
};
