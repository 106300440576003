/* eslint-disable no-template-curly-in-string */
import { Storage } from "@dashboard-v3/api";
import { TFunction } from "i18next";
import { nameFormatDefaults } from "pages/Rules/RuleActions/utils";
import { RuleType, Rule } from "pages/Rules/types";

/**
 * Init clean rules based on type
 */
export default function initRule(type: RuleType, t: TFunction): Rule | null {
  switch (type) {
    case "BLOCK_ATTACHMENTS":
      return blockAttachments();

    case "COPY_EMAILS":
      return copyEmails();

    case "MOVE_EMAILS":
      return moveEmails();

    case "REPLACE_ATTACHMENTS":
      return replaceAttachments();

    case "PROTECT_EMAILS":
      return protectEmails();

    case "DRAG_AND_DROP":
      return dragAndDrop();

    case "REQUEST_APPROVAL":
      return requestApproval(t);

    case "SEND_DELAY":
      return sendDelay();

    default:
      return null;
  }
}

function blockAttachments() {
  return {
    ...baseRule(),
    actionType: "BLOCK_ATTACHMENTS",
    blockAttachments: {
      contentFilter: null,
      emailFlow: {
        smtpPhase: false,
        fromTo: {
          fromDirection: { type: "ORGANIZATION" },
          toDirection: { type: "ANYONE" },
        },
        evaluateHeaders: false,
        bidirectional: true,
        filter: null,
      },
    },
  } as Rule;
}

function copyEmails() {
  return {
    ...baseRule(),
    actionType: "COPY_EMAILS",
    copyEmails: {
      contentToSave: {
        content: [{ type: "EML", nameFormat: nameFormatDefaults("EML") }],
        filter: null,
      },
      storage: emptyStorage(),
      archivingAddress: null,
    },
  } as Rule;
}

function moveEmails() {
  return {
    ...baseRule(),
    actionType: "MOVE_EMAILS",
    moveEmails: {
      contentToSave: {
        content: [{ type: "EML", nameFormat: nameFormatDefaults("EML") }],
        filter: null,
      },
      mailbox: null,
      storage: emptyStorage(),
      trash: true,
    },
  } as Rule;
}

function replaceAttachments() {
  return {
    ...baseRule(),
    actionType: "REPLACE_ATTACHMENTS",
    replaceAttachments: {
      contentToSave: {
        content: [
          {
            type: "ATTACHMENTS",
            nameFormat: nameFormatDefaults("ATTACHMENTS"),
          },
        ],
        filter: null,
      },
      emailFlow: null,
      mailbox: null,
      linksFormat: ["PDF", "LINK"],
      triggerOnShortCode: false,
      storage: emptyStorage(),
    },
  } as Rule;
}

function protectEmails() {
  return {
    ...baseRule(),
    actionType: "PROTECT_EMAILS",
    protectEmails: {
      contentToSave: {
        content: [
          {
            type: "ATTACHMENTS",
            nameFormat: nameFormatDefaults("ATTACHMENTS"),
          },
          { type: "PDF", nameFormat: nameFormatDefaults("PDF") },
        ],
        filter: null,
      },
      linksFormat: ["PDF", "LINK"],
      emailFlow: null,
      storage: emptyStorage(),
    },
  } as Rule;
}

function dragAndDrop() {
  return {
    ...baseRule(),
    actionType: "DRAG_AND_DROP",
    dragAndDrop: {
      contentToSave: {
        content: [{ type: "EML", nameFormat: nameFormatDefaults("EML") }],
        filter: null,
      },
      storageWebhook: {
        account: "",
        folders: [],
      },
      storage: {
        ...emptyStorage(),
        provider: "BOX",
        authentication: "ORGANIZATION",
      },
    },
  } as Rule;
}

function requestApproval(t: TFunction) {
  return {
    ...baseRule(),
    actionType: "REQUEST_APPROVAL",
    requestApproval: {
      emailFlow: null,
      contentFilter: null,
      supervisors: [{ entity: null, type: null }],
      expiration: { type: "DAYS", value: 7 },
      senderNotification: {
        accessLink: false,
        accessLinkLabel: t("approvalSteps.senderEmail.access"),
        bodyText: t("approvalSteps.senderEmail.body"),
        cancelLink: true,
        cancelLinkLabel: t("approvalSteps.senderEmail.cancel"),
        title: t("approvalSteps.senderEmail.title"),
      },
      supervisorNotification: {
        accessLink: false,
        accessLinkLabel: t("approvalSteps.supervisorEmail.access"),
        approveLink: true,
        approveLinkLabel: t("approvalSteps.supervisorEmail.approve"),
        bodyText: t("approvalSteps.supervisorEmail.body"),
        rejectLink: true,
        rejectLinkLabel: t("approvalSteps.supervisorEmail.reject"),
        subject: t("approvalSteps.supervisorEmail.title"),
        title: t("approvalSteps.supervisorEmail.title"),
      },
      doneNotification: {
        title: t("approvalSteps.doneEmail.title"),
        approvedBodyText: t("approvalSteps.doneEmail.approvedBodyText"),
        rejectedBodyText: t("approvalSteps.doneEmail.rejectedBodyText"),
      },
    },
  } as Rule;
}

function sendDelay() {
  return {
    ...baseRule(),
    actionType: "SEND_DELAY",
    sendDelay: {
      emailFlow: null,
      contentFilter: null,
      expiration: {
        expireAt: null,
        type: "MINUTES",
        value: 20,
      },
    },
  } as Rule;
}

function baseRule() {
  return {
    id: null,
    name: null,
    description: null,
    enabled: true,
    targetType: null,
  };
}

export function emptyStorage(): Storage {
  return {
    provider: null,
    authentication: null,
    syncAllByRule: false,
    userIds: [],
    asUsers: [],
    location: {
      folderStructure: null,
    },
    override: true,
    allManagedUserIds: false,
  };
}
