import { useTranslation } from "react-i18next";
import { Rule } from "../types";
import { Step } from "pages/Rules/CreateOrEdit/context/types";

export default function SendDelayDetails({
  rule,
  step,
}: {
  rule: Rule;
  step: Step;
}) {
  const { t } = useTranslation("rules");
  const expiration = rule.sendDelay.expiration;

  if (step !== "sendDelay") return <></>;

  return (
    <>
      {t("steps.sendDelayDetails", {
        value: expiration.value,
        type: t(`sendDelay.${expiration.type}`),
      })}
    </>
  );
}
