import { ChangeEventHandler, useState } from "react";
import {
  AuthenticationMethod,
  DeliveryManagerSettings,
} from "@dashboard-v3/api";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useStyles } from "./styled";
import { useTranslation } from "react-i18next";
import ButtonWithLoading from "components/ButtonWithLoading";
import apiRequest from "utils/apiRequestWithErrorCode";
import { useSnackbar } from "notistack";

interface Props {
  settings: DeliveryManagerSettings;
}

export default function EditConfiguration({ settings }: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [methods, setMethods] = useState<AuthenticationMethod[]>(
    settings?.loginOptions || []
  );

  const onLoginOptionsChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, checked } = e.target;

    setMethods(prev => {
      if (checked) {
        return [...prev, name as AuthenticationMethod];
      }

      return prev.filter(m => m !== name);
    });
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      await apiRequest<DeliveryManagerSettings>(
        "PATCH",
        "/delivery-manager/settings",
        { loginOptions: methods }
      );
    } catch (e) {
      enqueueSnackbar("Error while creating your configuration", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box className={classes.title}>
        <Typography variant="subtitle2">
          {t("deliveryManager.form.authTitle")}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t("deliveryManager.form.authSubtitle")}
        </Typography>
      </Box>
      <div className={classes.optionContainer}>
        {getAuthOptions(methods).map(option => (
          <FormControlLabel
            name={option.name}
            label={
              <Typography className={classes.optionLabel}>
                {t(option.label)}
              </Typography>
            }
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={option.checked}
                onChange={onLoginOptionsChange}
              />
            }
          />
        ))}
      </div>
      <Box mt={1} display="flex" flexDirection="column" gridGap="1rem">
        <ButtonWithLoading
          variant="contained"
          disableElevation
          loading={loading}
          onClick={onSubmit}
          disabled={!methods.length}
        >
          {t("deliveryManager.form.submitBtn")}
        </ButtonWithLoading>
      </Box>
    </>
  );
}

const getAuthOptions = (mehtods: AuthenticationMethod[]) => {
  const methods: AuthenticationMethod[] = ["CODE", "GOOGLE", "MICROSOFT"];

  return methods.map(method => {
    return {
      name: method,
      label: `mxheroShares.auth.method.${method}`,
      checked: mehtods.includes(method),
    };
  });
};
