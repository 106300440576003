import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    padding: "16px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 10,
  },
  title: { marginTop: 20, marginBottom: 5 },
  optionContainer: { marginBottom: 12 },
  optionLabel: { fontSize: 15 },
});
