/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import {
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Skeleton,
} from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import { OptionGroup } from "components/Styled";
import throttle from "utils/throttle";
import {
  getAccounts,
  getGroups,
  getReviewerOptions,
  getTags,
  getVariables,
} from "./api";
import { useRuleContext } from "../../../context/ruleContext";
import { getSupervisors } from "../../../context/helpers";

import { Supervisor } from "@dashboard-v3/api";
import { ReviewerOption } from "../types";

interface ReviewerSelectProps {
  index: number;
  value: Supervisor;
  onRemoveReviewer: () => void;
  onChange: (changes: Supervisor[]) => void;
}

type ReviewerState = {
  status: "IDLE" | "SEARCHING" | "LOADING";
  options: ReviewerOption[];
  selected: ReviewerOption;
  searchTerm: string;
};

export default function ReviewerAutocomplete({
  index,
  value,
  onChange,
  onRemoveReviewer,
}: ReviewerSelectProps) {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useRuleContext();
  const supervisors = getSupervisors(state.rule);
  const canRemoveReviewer = supervisors.length > 1;
  const [reviewerState, setReviewerState] = useState<ReviewerState>(initState);
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");

  function updateState(update: Partial<ReviewerState>) {
    setReviewerState(prev => ({ ...prev, ...update }));
  }

  useEffect(() => {
    async function initReviewers(value: Supervisor) {
      updateState({ status: "LOADING" });
      const option = await toOption(value);
      updateState({ selected: option });
      updateState({ status: "IDLE" });
    }

    if (isEdit) {
      initReviewers(value);
    }
  }, []);

  useEffect(() => {
    async function onSearch() {
      const { searchTerm } = reviewerState;
      const term = searchTerm.trim();

      throttle(async () => {
        if (!term) return updateState({ options: [] });

        updateState({ status: "SEARCHING" });
        try {
          const options = await getReviewerOptions(term);
          updateState({ options });
        } catch {
          enqueueSnackbar(t("approvalSteps.optionError.fetchError"), {
            variant: "error",
          });
        } finally {
          updateState({ status: "IDLE" });
        }
      }, 400);
    }

    onSearch();
  }, [reviewerState.searchTerm]);

  function handleOnChange(option: ReviewerOption) {
    if (!option) return;
    const changes = [...supervisors];
    changes[index] = { entity: option.value, type: option.type };
    updateState({ selected: option });
    onChange(changes);
  }

  function onInputChange(
    _,
    value: string,
    reason: AutocompleteInputChangeReason
  ) {
    if (reason === "input") updateState({ searchTerm: value });

    if (supervisors[index] && reason === "clear") {
      const update = [...supervisors];
      update[index] = { entity: null, type: null };
      updateState({ options: [], selected: null, searchTerm: value });
      onChange(update);
    }
  }

  function getOptionSelected(option: ReviewerOption, value: ReviewerOption) {
    if (!option && !value) return true;
    if (!option || !value) return false;

    if (option.type === value.type) {
      return option.label === value.label;
    }

    return false;
  }

  if (reviewerState.status === "LOADING") {
    return (
      <OptionGroup>
        <Skeleton
          variant="rect"
          animation="wave"
          height={56}
          style={{ marginTop: 24 }}
        />
      </OptionGroup>
    );
  }

  return (
    <Grid container alignItems="center" spacing={3} style={{ marginTop: 12 }}>
      <Grid item xs={11}>
        <Autocomplete
          fullWidth
          forcePopupIcon
          loading={reviewerState.status === "SEARCHING"}
          options={reviewerState.options}
          value={reviewerState.selected}
          noOptionsText={t("components:autocompleteNoOption")}
          getOptionLabel={opt => opt.label || ""}
          getOptionSelected={getOptionSelected}
          onChange={(_, value) => handleOnChange(value as ReviewerOption)}
          onInputChange={onInputChange}
          renderOption={option => (
            <div
              key={option.key}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="caption" color="textSecondary">
                {t(`approvalSteps.optionType.${option.type}`)}
              </Typography>
              <Typography>{option.label}</Typography>
            </div>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={t("approvalSteps.optionSearch.label")}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {reviewerState.status === "SEARCHING" && (
                      <CircularProgress color="primary" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={1}>
        {canRemoveReviewer && (
          <IconButton size="small" onClick={onRemoveReviewer}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}

const initState = (): ReviewerState => ({
  status: "IDLE",
  options: [],
  selected: null,
  searchTerm: "",
});

async function toOption(value: Supervisor | null): Promise<ReviewerOption> {
  if (!value) return null;

  const { type, entity } = value;

  if (type === "TAG") {
    const tagOptions = await getTags();
    return tagOptions.find(item => item.value === entity);
  }

  if (type === "EMAIL") {
    const emailOptions = await getAccounts();
    const foundEmail = emailOptions.find(item => item.value === entity);

    return foundEmail;
  }

  if (type === "GROUP") {
    const groupOptions = await getGroups();
    return groupOptions.find(item => item.value === entity);
  }
  if (type === "VARIABLE") {
    const varOptions = await getVariables();
    return varOptions.find(item => item.value === entity);
  }
}
