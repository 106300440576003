import { useTranslation } from "react-i18next";
import produce from "immer";
import {
  Box,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { getApprovalSteps } from "pages/Rules/CreateOrEdit/context/helpers";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";

import { RequestApproval } from "@dashboard-v3/api";

type ExpirationType = RequestApproval["expiration"]["type"];

export default function RequestExpiration() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { expiration } = getApprovalSteps(state.rule);
  const { isValid, error } = validateExpiration(expiration);

  const onChange = e => {
    const { name, value } = e.target;
    const update = produce(expiration, draft => {
      draft[name] = value;
    });

    dispatch({
      type: "updateStep",
      payload: {
        step: "approvalSteps",
        changes: { expiration: update },
      },
    });
  };

  const getMinMax = () => {
    const { type } = expiration;
    if (type === "DAYS") return { max: "30", min: "1" };
    if (type === "HOURS") return { max: "720", min: "1" };
  };

  return (
    <Box mb={2} style={{ maxWidth: "80%" }}>
      <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
        {t("approvalSteps.expiration.title")}
      </Typography>
      <Typography display="block" variant="caption" color="textSecondary">
        {t("approvalSteps.expiration.desc")}
      </Typography>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item lg={6}>
          <TextField
            fullWidth
            name="value"
            type="number"
            variant="outlined"
            label={t("approvalSteps.expiration.durationLabel")}
            value={expiration.value}
            onChange={onChange}
            inputProps={{ ...getMinMax() }}
            error={!isValid}
          />
        </Grid>
        <Grid item lg={6}>
          <SelectField<ExpirationType>
            name="type"
            label={t("approvalSteps.expiration.unitLabel")}
            options={["DAYS", "HOURS"]}
            value={expiration.type}
            onChange={onChange}
            renderOption={opt => (
              <MenuItem key={opt} value={opt}>
                {t(`approvalSteps.expiration.unit.${opt}`)}
              </MenuItem>
            )}
          />
        </Grid>
      </Grid>
      <FormHelperText margin="dense" error>
        {t(error)}
      </FormHelperText>
    </Box>
  );
}

const validateExpiration = (expiration: RequestApproval["expiration"]) => {
  const { type } = expiration;
  const value = Number(expiration.value);

  if (type === "DAYS" && value > 30) {
    return { isValid: false, error: "approvalSteps.expiration.maxDaysError" };
  }

  if (type === "DAYS" && value < 1) {
    return {
      isValid: false,
      error: "approvalSteps.expiration.minDaysError",
    };
  }

  if (type === "HOURS" && value > 720) {
    return {
      isValid: false,
      error: "approvalSteps.expiration.maxHoursError",
    };
  }

  if (type === "HOURS" && value < 1) {
    return {
      isValid: false,
      error: "approvalSteps.expiration.minHoursError",
    };
  }

  return { isValid: true, error: "" };
};
