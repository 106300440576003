import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { getStorage } from "pages/Rules/CloudStorage/utils";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";

export default function SyncByRuleSelector() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const storage = getStorage(state.rule);
  const currentValue = storage.syncAllByRule ? "ENABLED" : "DISABLED";

  const handleOnChange = (_, value: string) => {
    const syncAllByRule = value === "ENABLED";
    const { provider } = storage;
    const resetManagedUserIds = syncAllByRule && storage.allManagedUserIds;
    const resetStoreId =
      storage.storeId && syncAllByRule && provider !== "DROPBOX";

    dispatch({
      type: "updateStep",
      payload: {
        step: "cloudStorage",
        changes: {
          syncAllByRule,
          ...(resetStoreId && { storeId: "" }),
          ...(resetManagedUserIds && { allManagedUserIds: false }),
        },
      },
    });

    dispatch({
      type: "checkFolderCollaboration",
      payload: { syncAllByRule },
    });
  };

  return (
    <RadioGroup
      style={{ marginBottom: 24 }}
      value={currentValue}
      onChange={handleOnChange}
    >
      <FormControlLabel
        value="DISABLED"
        control={<Radio color="primary" size="small" />}
        label={
          <Typography variant="caption" style={{ fontSize: 14 }}>
            {t(`targetStep.syncAllByRule.disabled`)}
          </Typography>
        }
      />
      <FormControlLabel
        value="ENABLED"
        control={<Radio color="primary" size="small" />}
        label={
          <Typography variant="caption" style={{ fontSize: 14 }}>
            {t(`targetStep.syncAllByRule.enabled`)}
          </Typography>
        }
      />
    </RadioGroup>
  );
}
