import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, FormHelperText } from "@material-ui/core";
import PageLoading from "components/PageLoading";
import FromToSelector from "pages/Rules/FromToSelector";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { getEmailFlow } from "pages/Rules/CreateOrEdit/context/helpers";
import { State } from "pages/Rules/CreateOrEdit/context/types";
import { Option } from "pages/Rules/FromToSelector/types";
import WithIconHelper from "components/WithIconHelper";
import { getStorage } from "pages/Rules/CloudStorage/utils";
import { canUseStores } from "pages/Rules/CloudStorage/AdvancedOptions/SelectStores";
import FromToHelp from "./FromToHelp";
import SelectDirection from "./SelectDirection";
import SMTPSelector from "./SMTPSelector";
import SyncByRuleSelector from "./SyncByRuleSelector";
import AdvancedOptions from "./AdvancedOptions";
import ConfirmButton from "../ConfirmButton";

import { EmailFlow } from "@dashboard-v3/api";

type SelectedFlow = {
  from: Option;
  to: Option;
};

export default function EmailFlowOptions() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { actionType } = state.rule;
  const emailFlow = getEmailFlow(state.rule);
  const storage = getStorage(state.rule);
  const [selectedFlow, setSelectedFlow] = useState<SelectedFlow>();

  function updateEmailFlow(updates: Partial<EmailFlow>) {
    dispatch({
      type: "updateStep",
      payload: { step: "target", changes: updates },
    });
  }

  function validateFromTo(flow: { from: Option; to: Option }) {
    setSelectedFlow(flow);
    dispatch({
      type: "checkUnmanagedFlow",
      payload: { ...flow, smtpPhase: emailFlow.smtpPhase },
    });
  }

  function onSMTPChange(update: Partial<EmailFlow>) {
    const { smtpPhase } = update;

    updateEmailFlow(update);

    if (storage.storeId && !canUseStores(storage, emailFlow)) {
      dispatch({
        type: "updateStep",
        payload: {
          step: "cloudStorage",
          changes: { storeId: "" },
        },
      });
    }

    if (smtpPhase && storage.allManagedUserIds) {
      dispatch({
        type: "updateStep",
        payload: {
          step: "cloudStorage",
          changes: { allManagedUserIds: false },
        },
      });
    }

    dispatch({
      type: "checkUnmanagedFlow",
      payload: { ...selectedFlow, smtpPhase },
    });

    dispatch({
      type: "checkFolderCollaboration",
      payload: { smtpPhase },
    });
  }

  return (
    <>
      {state.asyncStatus === "validate_email_flow" && <PageLoading loading />}
      <div style={{ padding: "0 24px 24px" }}>
        {canSelectSMTP(state) && (
          <SMTPSelector
            ruleType={actionType}
            value={emailFlow.smtpPhase}
            onChange={onSMTPChange}
          />
        )}
        {canSelectSyncAllByRule(state) && <SyncByRuleSelector />}
        <Typography
          gutterBottom
          variant="subtitle1"
          style={{ fontWeight: "500" }}
        >
          {t("targetAllEmailsSent")}
        </Typography>
        <WithIconHelper
          position="after"
          text={<FromToHelp />}
          buttonStyles={{ marginLeft: "5px" }}
        >
          <Grid container direction="row" alignItems="flex-start" spacing={2}>
            <Grid item xs={3}>
              <SelectDirection
                value={emailFlow.bidirectional}
                onChange={bidirectional => updateEmailFlow({ bidirectional })}
                disabled={
                  state.rule.actionType === "REQUEST_APPROVAL" ||
                  emailFlow.smtpPhase
                }
              />
            </Grid>
            <Grid item xs={9}>
              <FromToSelector
                initialSelectedItem={emailFlow.fromTo}
                onChange={fromTo => updateEmailFlow({ fromTo })}
                onOptionSelect={validateFromTo}
                separator={
                  emailFlow.bidirectional
                    ? t("emailFlowSection.and")
                    : t("emailFlowSection.to")
                }
              />
            </Grid>
            {isInvalidFlow(state) && (
              <FormHelperText error variant="outlined" margin="dense">
                {t(mapErrorCheck(state))}
              </FormHelperText>
            )}
          </Grid>
        </WithIconHelper>

        <ConfirmButton
          onClick={() => dispatch({ type: "confirmStep", payload: "target" })}
          disabled={isConfirmDisabled(state)}
        />
      </div>
      <AdvancedOptions />
    </>
  );
}

function isConfirmDisabled(state: State) {
  if (state.steps?.target?.done) return true;
  return !state.steps.target.valid;
}

function isInvalidFlow(state: State) {
  return Boolean(state.checks.emailFlow?.error);
}

function mapErrorCheck(state: State) {
  const errorType = state.checks.emailFlow?.error;
  return `target.EMAIL_FLOW.checkError.${errorType}`;
}

function canSelectSMTP(state: State) {
  const { actionType } = state.rule;

  return actionType === "MOVE_EMAILS";
}

function canSelectSyncAllByRule(state: State) {
  const { actionType } = state.rule;

  return (
    actionType === "REPLACE_ATTACHMENTS" ||
    actionType === "COPY_EMAILS" ||
    actionType === "PROTECT_EMAILS"
  );
}
