import styled from "styled-components";
import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  withStyles,
} from "@material-ui/core";

export const PropertyLabel = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.82rem;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const PropertyData = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
  padding-bottom: 20px;
  padding-top: 10px;
`;

export const StyledAccordionSummary = withStyles({
  expanded: {},
  content: {
    margin: "8px 0",
    "&$expanded": {
      margin: "8px 0",
    },
  },
})(AccordionSummary);

export const StyledList = styled.ul`
  list-style-type: decimal;
  padding-inline-start: 1.2rem;
`;
