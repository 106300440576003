import { Draft } from "immer";
import { State } from "../../types";
import { Rule } from "@dashboard-v3/api";

export default function validate(draft: Draft<State>) {
  const expiration = draft.rule.sendDelay.expiration;
  if (!expiration) {
    draft.steps.action.valid = false;
    return false;
  }

  const isValid = expiration.type && expiration.value && expiration.value > 0;

  if (!isValid) {
    draft.steps.sendDelay.valid = false;
    return false;
  }

  const errorMax = validateMax(expiration.type, expiration.value);
  if (errorMax) {
    draft.steps.sendDelay.error = errorMax;
    draft.steps.sendDelay.valid = false;
    return false;
  }

  const errorMin = validateMin(expiration.type, expiration.value);
  if (errorMin) {
    draft.steps.sendDelay.error = errorMin;
    draft.steps.sendDelay.valid = false;
    return false;
  }

  draft.steps.sendDelay.valid = true;
  draft.steps.sendDelay.error = null;
  return true;
}

function validateMax(
  type: Rule["sendDelay"]["expiration"]["type"],
  value: number
) {
  let days = -1;

  switch (type) {
    case "DAYS":
      days = value;
      break;
    case "HOURS":
      days = value / 24;
      break;
    case "MINUTES":
      days = value / 60 / 24;
      break;
    default:
      break;
  }

  if (days <= 10) return null;

  return {
    type: "EXCEEDS_MAX",
    limitValue: 10,
    limitType: "DAYS",
  } as SendDelayLimitError;
}

function validateMin(
  type: Rule["sendDelay"]["expiration"]["type"],
  value: number
) {
  if (type === "MINUTES" && value <= 2) {
    return {
      type: "EXCEEDS_MIN",
      limitValue: 2,
      limitType: "MINUTES",
    } as SendDelayLimitError;
  }

  return null;
}

export type SendDelayLimitError = {
  type: "EXCEEDS_MAX" | "EXCEEDS_MIN";
  limitValue: number;
  limitType: Rule["sendDelay"]["expiration"]["type"];
};
