import { useTranslation } from "react-i18next";
import { Grid, Link } from "@material-ui/core";
import ErrorsAndWarnings from "./ErrorsAndWarnings";
import NoFolderFound from "./NoFolderFound";
import { Data } from "../DetailItem";
import { PropertyData, PropertyLabel, StyledList } from "../styled";

import { Rule, MxheroShare } from "@dashboard-v3/api";

interface Props {
  data: Data;
  rule: Rule;
  shares: MxheroShare[];
}

// TODO: Check this data type and use the correct types

type Event = {
  level: string;
  details?: Record<string, any>;
  code?: string;
};

export default function StorageExecutedEvent({ data, rule, shares }: Props) {
  const { t } = useTranslation(["tracing", "storage"]);
  const errorEvents = getEventsWithErrors(data?.save?.events || []);

  const getEventDetails = () => {
    if (!errorEvents.length) return null;

    const noFolderFoundEvt = errorEvents.find(
      e => e.code === "no_folder_found"
    );

    if (noFolderFoundEvt) {
      const { details } = noFolderFoundEvt;
      return <NoFolderFound rule={rule} details={details} />;
    }

    return <ErrorsAndWarnings events={errorEvents} />;
  };

  const isMxheroStorageShare = (id: string) => {
    return shares.some(s => s.shareId === id);
  };

  return (
    <Grid item xs={12}>
      {!!data.save.files.length && (
        <Grid item xs={12} style={{ marginBottom: "15px" }}>
          <PropertyLabel>{t("files")}:</PropertyLabel>
          <StyledList>
            {data.save.files.map(({ file, share: fileShare }) => {
              const fileShareId = getFileShareId(fileShare);
              const isMxheroShare = isMxheroStorageShare(fileShareId);
              return (
                <li key={file.id} style={{ paddingLeft: "4px" }}>
                  <Link
                    href={isMxheroShare ? fileShare.viewUrl : file.url}
                    variant="body1"
                    underline="always"
                    target="_blank"
                  >
                    {file.name}
                  </Link>

                  {isMxheroShare && (
                    <>
                      {" - "}
                      <Link
                        href={`/mxhero-shares/${getFileShareId(fileShare)}`}
                        variant="caption"
                        target="_blank"
                        color="textSecondary"
                        style={{ marginLeft: 3 }}
                      >
                        {t("editStorageShare")}
                      </Link>
                    </>
                  )}
                </li>
              );
            })}
          </StyledList>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
          <PropertyLabel>{t("common:user")}: </PropertyLabel>
          <PropertyData color="textPrimary">
            {data.save.storageUser}
          </PropertyData>
        </Grid>
        <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
          <PropertyLabel>{t("common:storage")}: </PropertyLabel>
          <PropertyData color="textPrimary">
            {t(data.save.storageType, { ns: "storage" })}
          </PropertyData>
        </Grid>
      </Grid>
      {getEventDetails()}
    </Grid>
  );
}

function getEventsWithErrors(events): Event[] {
  return events.reduce((accum, event) => {
    if (event.level === "warn" || event.level === "error") {
      accum.push(event);
    }

    return accum;
  }, []);
}

const getFileShareId = fileShareData => {
  if (!fileShareData?.viewUrl) return "";
  const parts = fileShareData.viewUrl.split("/");
  const id = parts[parts.length - 1];
  return id;
};
