import { Step } from "pages/Rules/CreateOrEdit/context/types";
import { Rule } from "pages/Rules/types";
import ActionDetails from "./ActionDetails";
import CloudStorageDetails from "./CloudStorageDetails";
import TargetDetails from "./TargetDetails";
import ApprovalStepsDetails from "./ApprovalStepsDetails";
import SendDelayDetails from "./SendDelayDetails";

type Params = {
  rule: Rule;
  step: Step;
};

export default function StepDetails({ rule, step }: Params) {
  switch (step) {
    case "action":
      return <ActionDetails rule={rule} />;

    case "target":
      return <TargetDetails rule={rule} />;

    case "cloudStorage":
      return <CloudStorageDetails rule={rule} />;

    case "approvalSteps":
      return <ApprovalStepsDetails rule={rule} />;

    case "sendDelay":
      return <SendDelayDetails rule={rule} step={step} />;

    case "storageFilepath":
      return null;
  }
}
