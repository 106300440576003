import { Draft } from "immer";
import { State } from "../../types";
import { getApprovalSteps } from "../../helpers";
import { isBlank } from "utils/string";
import {
  EmailExpiration,
  SenderNotification,
  SupervisorNotification,
} from "@dashboard-v3/api";

export default function validate(draft: Draft<State>) {
  const {
    supervisors,
    expiration,
    senderNotification,
    supervisorNotification,
  } = getApprovalSteps(draft.rule);

  if (!supervisors.length) {
    draft.steps.approvalSteps.valid = false;
    return;
  }

  const isValidExpiration = validateExpiration(expiration);
  const isValidSenderSettings = validateSettings(senderNotification);
  const isValidSupervisorSettings = validateSettings(supervisorNotification);
  const isValidSupervisors = !supervisors.some(
    s => isBlank(s.entity) || isBlank(s.type)
  );

  draft.steps.approvalSteps.valid =
    isValidSupervisors &&
    isValidSenderSettings &&
    isValidSupervisorSettings &&
    isValidExpiration;
}

function validateSettings(
  notification: SenderNotification | SupervisorNotification
) {
  for (let key in notification) {
    const value = notification[key];
    const isString = typeof value === "string";
    const shouldValidate = isString && isBlank(value);

    if (shouldValidate) {
      if (!key.endsWith("Label")) return false;
      const linkKey = key.replace("Label", "");
      if (notification[linkKey] === true) return false;
    }
  }

  return true;
}

function validateExpiration(expiration: EmailExpiration) {
  const { type } = expiration;
  const value = Number(expiration.value);

  if (type === "DAYS") return value <= 30 && value >= 1;

  if (type === "HOURS") return value <= 720 && value >= 1;

  return true;
}
