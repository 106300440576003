import { State } from "../types";
import { SendDelay } from "@dashboard-v3/api";
import produce from "immer";
import validateStep from "./validateStep";

export type UpdateSendDelay = {
  type: "updateSendDelay";
  payload: Payload;
};

type Payload = Partial<{ expiration: SendDelay["expiration"] }>;

export function updateSendDelay(state: State, payload: Payload) {
  return produce(state, draft => {
    const type = payload.expiration.type;
    const value = payload.expiration.value;

    const sendDelayExpiration = draft.rule.sendDelay.expiration;

    if (type) {
      sendDelayExpiration.type = type;
      draft.steps.sendDelay.done = false;
    }

    if (value !== undefined) {
      sendDelayExpiration.value = value;
      if (isNaN(value)) {
        sendDelayExpiration.value = null;
      }
      draft.steps.sendDelay.done = false;
    }

    validateStep("sendDelay", draft);
  });
}
