import { Step } from "pages/Rules/CreateOrEdit/context/types";
import { Rule, RuleType } from "pages/Rules/types";
import { useTranslation } from "react-i18next";
import {
  getApprovalSteps,
  typePath as getTypePath,
} from "pages/Rules/CreateOrEdit/context/helpers";
import FilepathDetails from "./StorageFilepathDetails";
import { Storage } from "@dashboard-v3/api";

type Params = {
  rule: Rule;
  step: Step;
  activeStep: number;
};

const StorageFilepathStep = 3;

export default function StepTitle({ rule, step, activeStep }: Params) {
  const { t } = useTranslation("rules");

  switch (step) {
    case "action":
      return <>{t(`actions.${rule.actionType}.title`)}</>;

    case "approvalSteps":
      const { supervisors } = getApprovalSteps(rule);

      if (!supervisors[0].entity) return null;

      return (
        <>{t("approvalSteps.details.title", { count: supervisors.length })}</>
      );

    case "target":
      return <>{t(rule.targetType)}</>;

    case "cloudStorage":
      const storage = getStorage(rule);
      if (storage.provider) {
        return (
          <>{t(`storageType.${storage.provider}.${storage.authentication}`)}</>
        );
      }
      return null;

    case "storageFilepath":
      if (activeStep >= StorageFilepathStep) {
        const location = getStorage(rule)?.location;
        return <FilepathDetails location={location} />;
      }
      return null;

    default:
      return null;
  }
}

function getStorage(rule: Rule): Storage {
  const type = rule.actionType as Exclude<
    RuleType,
    "BLOCK_ATTACHMENTS" | "REQUEST_APPROVAL" | "SEND_DELAY"
  >;
  return getTypePath(type, rule)?.storage;
}
