import { typePath } from "pages/Rules/CreateOrEdit/context/helpers";
import { Rule } from "pages/Rules/types";
import { useTranslation } from "react-i18next";
import { ItemWithMargin, UnstyledList } from "./Styles";

type DetailsProp = {
  rule: Rule;
};

export default function ActionDetails({ rule }: DetailsProp) {
  const { t } = useTranslation("rules");
  const content = getContent(rule);

  if (!content) return null;

  const items = content.reduce((result, item) => {
    if (item.type === "ATTACHMENTS") {
      result.push(
        <ItemWithMargin key="type-attachments">
          {t("attachments")}
        </ItemWithMargin>
      );
      return result;
    }

    if (item.type === "PDF") {
      result.push(
        <ItemWithMargin key="type-pdf">{t("emailBodyInPdf")}</ItemWithMargin>
      );
      return result;
    }

    if (item.type === "ORIGINAL") {
      result.push(
        <ItemWithMargin key="type-original">
          {t("ruleAction.saveFormat.original")}
        </ItemWithMargin>
      );
      return result;
    }

    result.push(
      <ItemWithMargin key="type-email" style={{ paddingRight: "10px" }}>
        {t("emailAs")}
        <span style={{ textTransform: "uppercase" }}>.{item.type}</span>
      </ItemWithMargin>
    );

    return result;
  }, []);

  return (
    <UnstyledList>
      <li>{t("saveThe")}</li>
      <>{items}</>
    </UnstyledList>
  );
}

function getContent(rule: Rule) {
  if (
    rule.actionType !== "BLOCK_ATTACHMENTS" &&
    rule.actionType !== "REQUEST_APPROVAL" &&
    rule.actionType !== "SEND_DELAY"
  ) {
    return typePath(rule.actionType, rule)?.contentToSave?.content;
  }
  return null;
}
