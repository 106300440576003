import { useTranslation } from "react-i18next";
import { DeliveryManagerSettings } from "@dashboard-v3/api";
import { useSnackbar } from "notistack";
import apiRequest from "utils/apiRequestWithErrorCode";
import { useState } from "react";
import ButtonWithLoading from "components/ButtonWithLoading";

interface Props {
  onSetUp: (settings: DeliveryManagerSettings) => void;
}

export default function CreateConfiguration({ onSetUp }: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);

    try {
      const settings = await apiRequest<DeliveryManagerSettings>(
        "POST",
        "/delivery-manager/settings",
        { loginOptions: ["CODE"] }
      );

      onSetUp(settings);
    } catch (e) {
      enqueueSnackbar(t("deliveryManager.errors.create"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ButtonWithLoading
      variant="contained"
      disableElevation
      loading={loading}
      style={{ width: "fit-content", marginTop: "10px" }}
      onClick={onClick}
    >
      {t("deliveryManager.newConfigBtn")}
    </ButtonWithLoading>
  );
}
