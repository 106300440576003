/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box, LinearProgress, Paper, Typography } from "@material-ui/core";
import apiRequest, { getErrorCode } from "utils/apiRequestWithErrorCode";
import EditConfiguration from "./EditConfiguration";
import CreateConfiguration from "./CreateConfiguration";
import { useStyles } from "./styled";

import { DeliveryManagerSettings } from "@dashboard-v3/api";

export default function DeliveryManager() {
  const { t } = useTranslation("storage");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [settings, setSettings] = useState<DeliveryManagerSettings>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function init() {
      setLoading(true);
      try {
        const settings = await apiRequest<DeliveryManagerSettings>(
          "GET",
          "/delivery-manager/settings"
        );
        setSettings(settings);
      } catch (e) {
        const err = getErrorCode(e);

        if (err !== "NOT_FOUND") {
          enqueueSnackbar(t("deliveryManager.errors.getSettings"), {
            variant: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    }

    if (!settings) {
      init();
    }
  }, []);

  if (loading) {
    return (
      <Paper className={classes.container}>
        <Box>
          <Typography>{t("deliveryManager.title")}</Typography>
          <Box>
            <Typography variant="caption" color="textSecondary">
              {t("deliveryManager.subtitle")}
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <LinearProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <Paper className={classes.container}>
      <Box>
        <Typography>{t("deliveryManager.title")}</Typography>
        <Box>
          <Typography variant="caption" color="textSecondary">
            {t("deliveryManager.subtitle")}
          </Typography>
        </Box>
      </Box>
      {Boolean(settings) ? (
        <EditConfiguration settings={settings} />
      ) : (
        <CreateConfiguration onSetUp={settings => setSettings(settings)} />
      )}
    </Paper>
  );
}
