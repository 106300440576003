import { useTranslation } from "react-i18next";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import OnedriveSync from "./OnedriveSync";
import BoxSync from "./BoxSync";
import GappsSync from "./GappsSync";
import OfficeMailboxSync from "./OfficeMailboxSync";
import OfficeDirectorySync from "./OfficeDirectorySync";
import OpenAIOption from "./OpenAIOption";
import MxHeroApi from "./MxHeroApi";
import MxheroShareLinks from "./MxheroShares";

import { StorageSettings } from "@dashboard-v3/api";
import DeliveryManager from "./MxheroDeliveryManager";

interface ApplicationsProps {
  settings: StorageSettings;
  onChange: (update: StorageSettings) => void;
}

export default function Applications({
  settings,
  onChange,
}: ApplicationsProps) {
  const { t } = useTranslation("storage");

  return (
    <>
      <List component={Paper} style={{ marginTop: "20px" }}>
        <ListItem style={{ display: "block" }}>
          <ListItemText
            primary={t("boxSync")}
            secondary={t("boxSyncExplanation")}
          />
          <BoxSync settings={settings} onAccountSync={onChange} />
        </ListItem>
        <Divider variant="middle" style={{ margin: "10px 16px" }} />
        <ListItem style={{ display: "block" }}>
          <ListItemText
            primary={t("googleDriveSync")}
            secondary={t("googleDriveSyncExplanation")}
          />
          <GappsSync settings={settings} onAccountSync={onChange} />
        </ListItem>
        <Divider variant="middle" style={{ margin: "10px 16px" }} />
        <ListItem style={{ display: "block" }}>
          <ListItemText
            primary={t("onedriveSync")}
            secondary={t("onedriveSyncExplanation")}
          />
          <OnedriveSync settings={settings} onAccountSync={onChange} />
        </ListItem>
        <Divider variant="middle" style={{ margin: "10px 16px" }} />
        <ListItem style={{ display: "block" }}>
          <ListItemText
            primary={t("officeMailboxSync.title")}
            secondary={t("officeMailboxSync.description")}
          />
          <OfficeMailboxSync settings={settings} onAccountSync={onChange} />
        </ListItem>
        {settings?.office365?.directory !== null && (
          <>
            <Divider variant="middle" style={{ margin: "10px 16px" }} />
            <ListItem style={{ display: "block" }}>
              <ListItemText
                primary={t("Office365 Directory")}
                secondary={t("Verify your connection with Office365 Directory")}
              />
              <OfficeDirectorySync
                settings={settings}
                onAccountSync={onChange}
              />
            </ListItem>
          </>
        )}
      </List>
      <OpenAIOption />
      <MxHeroApi />
      <MxheroShareLinks />
      <DeliveryManager />
    </>
  );
}
