/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import apiRequest, { fetcher } from "utils/apiRequestWithErrorCode";
import StorageExecutedEvent from "./StorageExecutedEvent";
import EventMessage from "./EventMessage";
import FailDetails from "./FailDetails";
import Title from "./Title";
import MailDeliveredData from "./MailDeliveredData";
import { PropertyData, PropertyLabel, StyledList } from "./styled";

import {
  TracingEvent,
  TracingEventDetails,
  Rule,
  MxheroShare,
} from "@dashboard-v3/api";
import useSWR from "swr";
import MailDelivered from "./MailDelivered";

export type Data = {
  save?: {
    events: any;
    files?: any[];
    storageUser: string;
    storageType: string;
  };
};

interface Props {
  event: TracingEventDetails;
  recipient: string;
  includeAll?: boolean;
}

export default function DetailItem({ event, recipient, includeAll }: Props) {
  const { t } = useTranslation(["tracing", "rules"]);
  const [storageShares, setStorageShares] = useState<MxheroShare[]>([]);
  const { action, data, eventId } = event;
  const itemInfo = [];

  const { data: rule } = useSWR<Rule>(
    event.data?.ruleId ? `/rules/${event.data?.ruleId}` : null,
    fetcher,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    const { save } = data as Data;
    const shouldFetchShares =
      action === "storage_executed" &&
      save.storageType === "MXHERO_STORAGE" &&
      !storageShares.length;

    async function fetchStorageShareById() {
      const ids = save?.files
        .filter(f => !!f?.share?.viewUrl)
        .map(f => {
          const parts = f.share.viewUrl.split("/");
          return parts[parts.length - 1];
        });

      if (ids.length) {
        const res = await Promise.all(
          ids.map(id => {
            return apiRequest<MxheroShare>("GET", `/mxhero-shares/${id}`);
          })
        );
        setStorageShares(res);
      }
    }

    if (shouldFetchShares) fetchStorageShareById();
  }, [data]);

  switch (action) {
    case "mail_received":
      if (includeAll)
        itemInfo.push(
          <Grid item xs={12} key={eventId}>
            <PropertyLabel>{t("subject")}:</PropertyLabel>
            <PropertyData color="textPrimary">
              {data.subject as string}
            </PropertyData>
            <Grid container spacing={2} style={{ marginTop: "12px" }}>
              <MailDeliveredData data={data as TracingEvent["data"]} />
            </Grid>
          </Grid>
        );
      break;

    case "mail_delivered":
      // Returns directly because use a custom Title component
      return (
        <MailDelivered key={eventId} event={event} recipient={recipient} />
      );

    case "mail_drop":
    case "mail_delivered_failed":
      break;

    case "mail_reject":
      itemInfo.push(
        <Grid item lg={3} key={eventId}>
          <PropertyLabel>{t("reason")}</PropertyLabel>
          <PropertyData color="textPrimary">
            {data.reason as string}
          </PropertyData>
        </Grid>
      );
      break;

    case "rule_executed":
      itemInfo.push(
        <React.Fragment key={eventId}>
          <Grid item xs={3}>
            <PropertyLabel>{t("action")}</PropertyLabel>
            <PropertyData color="textPrimary">
              {rule && (
                <Link href={`/rules/${data.ruleId}/edit`} underline="always">
                  {t(`rules:list.actionTypes.${data.actionType}`)}
                </Link>
              )}
              {!rule && t(`rules:list.actionTypes.${data.actionType}`)}
            </PropertyData>
          </Grid>
          {rule && (
            <Grid item xs={3}>
              <PropertyLabel>{t("name")}</PropertyLabel>
              <PropertyData color="textPrimary">
                <Link href={`/rules/${rule.id}/edit`} underline="always">
                  {rule.name}
                </Link>
              </PropertyData>
            </Grid>
          )}
        </React.Fragment>
      );
      break;

    case "storage_executed":
      itemInfo.push(
        <StorageExecutedEvent
          key={eventId}
          data={data}
          rule={rule}
          shares={storageShares}
        />
      );
      break;

    case "execution_failed":
    case "execution_unexpected_error": {
      itemInfo.push(
        <React.Fragment key={eventId}>
          <Grid item xs={rule ? 3 : 12} style={{ marginBottom: "15px" }}>
            <PropertyLabel>{t("action")}</PropertyLabel>
            <PropertyData color="textPrimary">
              {rule && (
                <Link href={`/rules/${data.ruleId}/edit`} underline="always">
                  {t(`rules:list.actionTypes.${data.actionType}`)}
                </Link>
              )}
              {!rule && t(`rules:list.actionTypes.${data.actionType}`)}
            </PropertyData>
          </Grid>
          {rule && (
            <Grid item xs={3} style={{ marginBottom: "15px" }}>
              <PropertyLabel>{t("name")}</PropertyLabel>
              <PropertyData color="textPrimary">
                <Link href={`/rules/${rule.id}/edit`} underline="always">
                  {rule.name}
                </Link>
              </PropertyData>
            </Grid>
          )}
          <Grid item xs={12}>
            <EventMessage message={data.executionMessage as string} />
            <FailDetails details={data.executionDetails} />
          </Grid>
        </React.Fragment>
      );
      break;
    }

    case "eval_filter_failed":
      itemInfo.push(
        <React.Fragment key={eventId}>
          <Grid item xs={rule ? 3 : 12} style={{ marginBottom: "15px" }}>
            <PropertyLabel>{t("action")}</PropertyLabel>
            <PropertyData color="textPrimary">
              {rule && (
                <Link href={`/rules/${data.ruleId}/edit`} underline="always">
                  {t(`rules:list.actionTypes.${data.actionType}`)}
                </Link>
              )}
              {!rule && t(`rules:list.actionTypes.${data.actionType}`)}
            </PropertyData>
          </Grid>
          {rule && (
            <Grid xs={3} style={{ marginBottom: "15px" }}>
              <PropertyLabel>{t("name")}</PropertyLabel>
              <PropertyData color="textPrimary">
                <Link href={`/rules/${rule.id}/edit`} underline="always">
                  {rule.name}
                </Link>
              </PropertyData>
            </Grid>
          )}
          <Grid item xs={12}>
            <PropertyData color="textPrimary">
              {availableMessages.includes(data.evalMessage as string)
                ? t(`filter_failed_msg.${data.evalMessage as string}`)
                : (data.evalMessage as string)}
            </PropertyData>
            <FailDetails details={data.evalDetails} />
          </Grid>
        </React.Fragment>
      );
      break;

    case "mail_split":
      const recipients = data.allRecipients as string[];
      itemInfo.push(
        <Grid item xs={12}>
          {!!recipients.length && (
            <Grid item xs={12} style={{ marginBottom: "15px" }}>
              <PropertyLabel>{t("recipients")}:</PropertyLabel>
              <StyledList>
                {recipients.map(recipient => (
                  <li key={recipient} style={{ paddingLeft: "4px" }}>
                    {recipient}
                  </li>
                ))}
              </StyledList>
            </Grid>
          )}
        </Grid>
      );
      break;

    case "storage_requested":
      break;
    default:
      // Don't delete this, see here: https://mxhero.atlassian.net/browse/MXH-3655?focusedCommentId=38645
      return null;
  }

  return (
    <>
      <Title timestamp={event.createdAt}>
        <Typography style={{ fontWeight: "500" }}>
          {t(`eventActionType.${event.action}`)}
        </Typography>
      </Title>

      {itemInfo}
    </>
  );
}

const availableMessages = [
  "mail_content_without_attachments",
  "sender_filtered_by_rule",
  "rule_shortcode_avoid_processing",
  "rule_shortcode_sender_not_managed_by_rule",
  "mail_content_is_tnef",
  "mail_content_failed_to_check_filter",
  "flow_filter_from_to",
  "eval_email_flow_managed_failed",
];
